import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '../form'

// When the user creates a new label, update the list of available labels
//to choose from, so the user can immediately apply their new label.
remoteForm('.js-create-runner-label-form', async function (form, wants) {
  let response
  const labelsMenu = form.closest<HTMLElement>('.js-runner-labels-menu')!
  const errorContainer = labelsMenu.querySelector<HTMLElement>('.js-runner-label-error')!

  try {
    response = await wants.html()
  } catch (error) {
    if (error.response.json?.message && errorContainer) {
      errorContainer.textContent = error.response.json.message
      errorContainer.hidden = false
    }

    return
  }

  if (!response || !response.html) return

  if (errorContainer) {
    errorContainer.hidden = true
    errorContainer.textContent = ''
  }

  const input = response.html.querySelector<HTMLInputElement>('.js-label-input')
  const list = labelsMenu.querySelector<HTMLElement>('.js-filterable-runner-labels')!
  list.prepend(response.html)

  // Select added label. This will trigger any listeners for label selection as well
  input?.click()

  const field = labelsMenu.querySelector<HTMLInputElement>('.js-label-filter-field')!
  field.value = ''
  field.focus()
})

function submitOnMenuClose({currentTarget}: Event) {
  if (currentTarget instanceof Element) {
    const form = currentTarget.querySelector<HTMLFormElement>('.js-runner-labels-form')!
    requestSubmit(form)
  }
}

function addLabelsOnMenuClose({currentTarget}: Event) {
  if (currentTarget instanceof Element) {
    const labels = currentTarget.querySelector<HTMLDivElement>('.js-filterable-runner-labels')!
    const menuItems = labels.getElementsByClassName('select-menu-item')
    const checkedLabels = []
    for (const menuItem of menuItems) {
      if (menuItem.attributes.getNamedItem('aria-checked')?.value === 'true') {
        const labelElement = menuItem.querySelector('.select-menu-item-text')
        if (labelElement instanceof HTMLElement) {
          checkedLabels.push(labelElement.textContent)
        }
      }
    }

    const containerDiv = currentTarget.closest<HTMLDivElement>('.js-label-container')!
    const labelTemplate = containerDiv.querySelector<HTMLDivElement>('#premium-runner-label-template')!
    const detailsMenu = containerDiv.querySelector('#premium-runner-label-details-list')!

    for (let i = containerDiv.children.length - 2; i > 0; i--) {
      containerDiv.removeChild(containerDiv.children[i])
    }

    for (const label of checkedLabels) {
      const node = labelTemplate.cloneNode(true)
      if (node instanceof HTMLElement) {
        node.removeAttribute('hidden')
        node.textContent = label
        containerDiv.appendChild(node)
      }
    }

    containerDiv.appendChild(detailsMenu)
  }
}

// On item selection, add listener to submit form on menu close
on(
  'details-menu-selected',
  '.js-runner-labels-details',
  function (event) {
    const menu = event.currentTarget
    menu.addEventListener('toggle', submitOnMenuClose, {once: true})
  },
  {capture: true}
)

// On item selection, add listener to submit form on menu close
on(
  'details-menu-selected',
  '.js-runner-labels-details-list',
  function (event) {
    const menu = event.currentTarget
    menu.addEventListener('toggle', addLabelsOnMenuClose, {once: true})
  },
  {capture: true}
)
